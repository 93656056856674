.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.innovation-blue-text {
  font-size: 4rem; /* Adjust the size as needed */
  font-weight: bold;
  background: linear-gradient(90deg, #00aaff, #00ffdd);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase; /* To ensure the text is in uppercase */
  margin: 0; /* Adjust the margin as needed */
  padding: 0; /* Adjust the padding as needed */
}
.innovation-orange-text {
  font-size: 4rem; /* Adjust the size as needed */
  font-weight: bold;
 background: linear-gradient(90deg, #ff9a76, #ff6a00, #ff4500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase; /* To ensure the text is in uppercase */
  margin: 0; /* Adjust the margin as needed */
  padding: 0; /* Adjust the padding as needed */
}
.navbar-gradient {
 background: linear-gradient(90deg, #0d47a1, #1976d2, #42a5f5);

}
@media only screen and (max-width: 600px) {
.innovation-blue-text {
  font-size: 3rem; /* Adjust the size as needed */
}
.innovation-orange-text {
  font-size: 3rem; /* Adjust the size as needed */
}
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.text-gray{
  color: gray;
}