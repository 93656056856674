/* Dropdown container - needed to position the dropdown content */
.dropdown {
  float: left;
  overflow: hidden;
}
ul {
  list-style-type: none; /* Removes bullet points */
}
nav ul {
  list-style-type: none; /* Removes the bullets */
  padding: 0; /* Optional: removes extra space */
  margin: 0; /* Optional: removes extra space */
}

/* Optional: Style the list items */
nav ul li {
  display: inline; /* Make the list items horizontal */
  margin-right: 15px; /* Adds space between items */
}

/* Style the dropdown button to fit inside the topnav */
.dropdown .dropbtn {
  /* font-size: 17px; */
  border: none;
  outline: none;
  color: black;
  /* padding: 14px 16px; */
  background-color: inherit;
  font-family: inherit;
  /* margin: 0; */
}

/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  /* margin-top: 28px; */
}

/* Style the links inside the dropdown */
.dropdown-content p {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
.dropdown-content a:hover {
  background-color: #ddd;
  color: black;
}

/* Show the dropdown menu when the user moves the mouse over the dropdown button */
.dropdown:hover .dropdown-content {
  display: block;
}
