.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* width: 40%; */
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.services-container {
  max-width: 100%;
  min-width: 100%;
  overflow-x: auto;
}

.get-started-btn {
  background-color: #ff5569;
  min-width: 200px;
  min-height: 50px;
  border-radius: 10px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  color: white;
}

/* From Uiverse.io by Prince4fff */
.product-card p {
  font-size: 22px;
  font-weight: 400;
  line-height: 20px;
  color: #000;
}

.product-card p.small {
  font-size: 14px;
}

.go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #00838d;
  border-radius: 0 4px 0 32px;
}

.go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.card1 {
  display: block;
  position: relative;
  /* max-width: 362px; */
  min-height: 180px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;

  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
@media only screen and (max-width: 600px) {
.card1 {
  display: block;
  position: relative;
  /* max-width: 305px; */
  min-height: 120px;
  background-color: #f2f8f9;
  border-radius: 4px;
  padding: 32px 24px;

  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;
}
}

.card1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -16px;
  right: -16px;
  background: #00838d;
  height: 32px;
  width: 32px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 50% 50%;
  transition: transform 0.25s ease-out;
}

.card1:hover:before {
  transform: scale(27);
}

.card1:hover p {
  transition: all 0.3s ease-out;
  color: rgba(255, 255, 255, 0.8);
}

.card1:hover h3 {
  transition: all 0.3s ease-out;
  color: #fff;
}
